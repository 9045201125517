/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS231P
 * 화면 설명: 모바일청약 중단건 진행현황 팝업
              AS-IS  TSSPS231P 전환 
 */
<template>
  <ur-page-container title="모바일청약 진행현황" :show-title="true" type="subpage" class="msp" :action-type="actionSlot">
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap bgcolor-1">
      <ur-box-container alignH="center" componentid="" direction="column" class="pb82">
        <ur-box-container alignV="start" componentid="" direction="column" class="card-list-wrap list-ty3">
          
          <!-- 피보험자 -->
          <mo-list-item>
            <msp-expand btn-area-first title-first expanded expand-only-btn ref="expItem_insuredPrgPhseCd" class="mo-list-expand" btn-area-class="firstdiv-full">
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 blue2" text="" shape="status">피보험자</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62">
                        <span @click.stop="fn_PopupCustNmUiCard(insuredMobslChnlCustId)">{{insuredNm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="insuredPrgPhseCd === '00'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="insuredPrgPhseCd === '05'" >종료</mo-badge>                        
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2" v-if="insuredKakaoSn !== '0'">
                        <mo-button sm class="ns-btn-round blue " color="primary" @click="fn_KaKaoSend('2')" :disabled="insuredMobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button v-show="!isCorpNomnPinsCoprResnt" sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('2')" :disabled="insuredMobpsElstYn === 'Y'">바로열기</mo-button>
                      </div>
                      <!-- 피보험자동의대상여부:Y, 피보험자동의구분코드:U:미동의 -->
                      <div class="dsG flex-1" v-if="insrdCnsntObjYn === 'Y'">
                        <!-- 활성화 : 피보험자전자서명완료여부:Y, 피보험자동의구분코드:U:미동의, FC전자서명여부:N -->
                        <!-- 표시 : 피보험자동의구분코드:U -->
                        <msp-bottom-select v-show="insrdCnsntObjYn === 'Y'" ref="bottomSelect" sm class="ns-dropdown-sheet round-dropdown mt18" :class="[{'red' : insuredMobpsElstYn === 'Y' && insrdCnsntScCd === 'U'}]" :itemValue="'key'" :itemText="'label'" v-model="insrdCnsntType.key" :items="insrdCnsntTypes" placeholder="피보험자 동의 필요" bottom-title="서면동의서촬영/지문촬영" v-if="insrdCnsntScCd === 'U'" :disabled="!(insuredMobpsElstYn === 'Y' && insrdCnsntScCd === 'U' && fcMobpsElstYn === 'N')" @input="fn_InsrdCnsntOnClick('2')" closeBtn/>
                        <!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <!-- 표시 : 피보험자동의구분코드:D:서면동의서, L:생체인증-->
                        <div class="mt4" v-if="insrdCnsntScCd === 'D' || insrdCnsntScCd === 'L'">
                          <mo-icon icon="msp-is-checked" class="fs26 mr4 valignM" /><span class="fs16rem crTy-bk7 valignM">확인완료</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : insuredPrgPhseCd === '00'}, {'completed' : insuredPrgPhseCd === '01' || insuredPrgPhseCd === '02' || insuredPrgPhseCd === '03' || insuredPrgPhseCd === '04' || insuredPrgPhseCd === '05'}]">
                  <span class="list-step fs14rem">시작</span>
                </div>
                <div class="ing-item" :class="[{'ing' : insuredPrgPhseCd === '01'}, {'completed' : insuredPrgPhseCd === '02' || insuredPrgPhseCd === '03' || insuredPrgPhseCd === '04' || insuredPrgPhseCd === '05'}]">
                  <span class="list-step fs14rem">필수동의</span>
                </div>
                <div class="ing-item" :class="[{'ing' : insuredPrgPhseCd === '02'}, {'completed' : insuredPrgPhseCd === '03' || insuredPrgPhseCd === '04' || insuredPrgPhseCd === '05'}]">
                  <span class="list-step fs14rem">상품설명서 작성</span>
                </div>
                <div class="ing-item" :class="[{'ing' : insuredPrgPhseCd === '03'}, {'completed' : insuredPrgPhseCd === '04' || insuredPrgPhseCd === '05'}]">
                  <span class="list-step fs14rem">청약정보확인</span>
                </div>
                <div class="ing-item" :class="[{'ing' : insuredPrgPhseCd === '04'}, {'completed' : insuredPrgPhseCd === '05'}]">
                  <span class="list-step fs14rem">계약전 알릴의무사항</span>
                </div>
                <div class="ing-item" :class="[{'ing' : insuredPrgPhseCd === '05'}]">
                  <span class="list-step fs14rem">종료</span>
                </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>        
          <!-- 피보험자 end -->

          <!-- 종피보험자 -->
          <mo-list-item  v-show="isAddInsuredStateShow">
            <msp-expand btn-area-first title-first expanded expand-only-btn ref="expItem_addInsuredPrgPhseCd" class="mo-list-expand" btn-area-class="firstdiv-full"> <!-- expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 blue" text="" shape="status">종피보험자</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62">
                        <span>{{addInsuredNm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="addInsuredPrgPhseCd === '00'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="addInsuredPrgPhseCd === '05'" >종료</mo-badge>
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2" v-if="addInsuredKakaoSn !== '0'">
                        <mo-button sm class="ns-btn-round blue" color="primary" @click="fn_KaKaoSend('3')" :disabled="addInsuredMobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('3')" :disabled="addInsuredMobpsElstYn === 'Y'">바로열기</mo-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : addInsuredPrgPhseCd === '00'}, {'completed' : addInsuredPrgPhseCd === '01' || addInsuredPrgPhseCd === '02' || addInsuredPrgPhseCd === '03' || addInsuredPrgPhseCd === '04' || addInsuredPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">시작</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : addInsuredPrgPhseCd === '01'}, {'completed' : addInsuredPrgPhseCd === '02' || addInsuredPrgPhseCd === '03' || addInsuredPrgPhseCd === '04' || addInsuredPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">필수동의</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : addInsuredPrgPhseCd === '02'}, {'completed' : addInsuredPrgPhseCd === '03' || addInsuredPrgPhseCd === '04' || addInsuredPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">상품설명서 작성</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : addInsuredPrgPhseCd === '03'}, {'completed' : addInsuredPrgPhseCd === '04' || addInsuredPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">청약정보확인</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : addInsuredPrgPhseCd === '04'}, {'completed' : addInsuredPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">계약전 알릴의무사항</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : addInsuredPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">종료</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <!-- 종피보험자 end -->

          <!-- 예금주 -->
          <mo-list-item  v-show="isDpstrStateShow">
            <msp-expand btn-area-first title-first expanded expand-only-btn ref="expItem_dpstrPrgPhseCd" class="mo-list-expand" btn-area-class="firstdiv-full"> <!-- expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 green2" text="" shape="status">예금주</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62"> 
                        <span @click.stop="fn_PopupCustNmUiCard(dpstrMobslChnlCustId)">{{dpstrNm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="dpstrPrgPhseCd === '00'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="dpstrPrgPhseCd === '05'" >종료</mo-badge>
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2" v-if="dpstrKakaoSn !== '0'">
                        <mo-button sm class="ns-btn-round blue" color="primary" @click="fn_KaKaoSend('6')" :disabled="dpstrMobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('6')" :disabled="dpstrMobpsElstYn === 'Y'">바로열기</mo-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : dpstrPrgPhseCd === '00'}, {'completed' : dpstrPrgPhseCd === '01' || dpstrPrgPhseCd === '02' || dpstrPrgPhseCd === '03' || dpstrPrgPhseCd === '04' || dpstrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">시작</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : dpstrPrgPhseCd === '01' || dpstrPrgPhseCd === '02'}, {'completed' : dpstrPrgPhseCd === '03' || dpstrPrgPhseCd === '04'|| dpstrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">필수동의</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : dpstrPrgPhseCd === '03' || dpstrPrgPhseCd === '04'}, {'completed' : dpstrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">청약정보확인</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : dpstrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">종료</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <!-- 예금주 end -->
          
          <!-- 친권자1 -->
          <mo-list-item v-show="isPrtr1StateShow">
            <msp-expand btn-area-first title-first expanded expand-only-btn ref="expItem_prtr1PrgPhseCd" class="mo-list-expand" btn-area-class="firstdiv-full">
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 gray" text="" shape="status">친권자1</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62">
                        <span>{{prtr1Nm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="prtr1PrgPhseCd === '00'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="prtr1PrgPhseCd === '05'" >종료</mo-badge>
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2" v-if="prtr1KakaoSn !== '0'">
                        <mo-button sm class="ns-btn-round blue" color="primary" @click="fn_KaKaoSend('4')" :disabled="prtr1MobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('4')" :disabled="prtr1MobpsElstYn === 'Y'">바로열기</mo-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : prtr1PrgPhseCd === '00'}, {'completed' : prtr1PrgPhseCd === '01' || prtr1PrgPhseCd === '02' || prtr1PrgPhseCd === '03' || prtr1PrgPhseCd === '04' || prtr1PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">시작</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr1PrgPhseCd === '01'}, {'completed' : prtr1PrgPhseCd === '02' || prtr1PrgPhseCd === '03' || prtr1PrgPhseCd === '04' || prtr1PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">필수동의</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr1PrgPhseCd === '02'}, {'completed' : prtr1PrgPhseCd === '03' || prtr1PrgPhseCd === '04' || prtr1PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">상품설명서 작성</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr1PrgPhseCd === '03'}, {'completed' : prtr1PrgPhseCd === '04' || prtr1PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">청약정보확인</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr1PrgPhseCd === '04'}, {'completed' : prtr1PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">계약전 알릴의무사항</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr1PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">종료</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <!-- 친권자1 end -->

          <!-- 친권자2 -->
          <mo-list-item  v-show="isPrtr2StateShow">
            <msp-expand btn-area-first title-first expanded expand-only-btn ref="expItem_prtr2PrgPhseCd" class="mo-list-expand" btn-area-class="firstdiv-full"> <!-- expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 gray" text="" shape="status">친권자2</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62">
                        <span>{{prtr2Nm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="prtr2PrgPhseCd === '00'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="prtr2PrgPhseCd === '05'" >종료</mo-badge>
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2" v-if="prtr2KakaoSn !== '0'">
                        <mo-button sm class="ns-btn-round blue" color="primary" @click="fn_KaKaoSend('5')" :disabled="prtr2MobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('5')" :disabled="prtr2MobpsElstYn === 'Y'">바로열기</mo-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : prtr2PrgPhseCd === '00'}, {'completed' : prtr2PrgPhseCd === '01' || prtr2PrgPhseCd === '02' || prtr2PrgPhseCd === '03' || prtr2PrgPhseCd === '04' || prtr2PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">시작</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr2PrgPhseCd === '01'}, {'completed' : prtr2PrgPhseCd === '02' || prtr2PrgPhseCd === '03' || prtr2PrgPhseCd === '04' || prtr2PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">필수동의</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr2PrgPhseCd === '02'}, {'completed' : prtr2PrgPhseCd === '03' || prtr2PrgPhseCd === '04' || prtr2PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">상품설명서 작성</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr2PrgPhseCd === '03'}, {'completed' : prtr2PrgPhseCd === '04' || prtr2PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">청약정보확인</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr2PrgPhseCd === '04'}, {'completed' : prtr2PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">계약전 알릴의무사항</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : prtr2PrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">종료</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <!-- 친권자2 end -->
        
          <!-- 계약자 -->
          <mo-list-item  v-show="isContrStateShow">
            <msp-expand btn-area-first title-first expanded expand-only-btn ref="expItem_contrPrgPhseCd" class="mo-list-expand" btn-area-class="firstdiv-full"> <!-- expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 green" text="" shape="status">계약자</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62">
                        <span @click.stop="fn_PopupCustNmUiCard(contrMobslChnlCustId)">{{contrNm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="contrPrgPhseCd === '00'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="contrPrgPhseCd === '05'" >종료</mo-badge>
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2" v-if="contrKakaoSn !== '0'">
                        <mo-button sm class="ns-btn-round blue" color="primary" @click="fn_KaKaoSend('1')" :disabled="contrMobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button v-show="!isCorpNomnPinsCoprResnt" sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('1')" :disabled="contrMobpsElstYn === 'Y'">바로열기</mo-button><!-- ASR240900190 / [Sub]법인명의 개인보험 모청 - 공동대표 적용 (법인공동인증서)  -->
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : contrPrgPhseCd === '00'}, {'completed' : contrPrgPhseCd === '01' || contrPrgPhseCd === '02' || contrPrgPhseCd === '03' || contrPrgPhseCd === '04' || contrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">시작</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : contrPrgPhseCd === '01'}, {'completed' : contrPrgPhseCd === '02' || contrPrgPhseCd === '03' || contrPrgPhseCd === '04' || contrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">필수동의</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : contrPrgPhseCd === '02'}, {'completed' : contrPrgPhseCd === '03' || contrPrgPhseCd === '04' || contrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">상품설명서 작성</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : contrPrgPhseCd === '03'}, {'completed' : contrPrgPhseCd === '04' || contrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">청약정보확인</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : contrPrgPhseCd === '04'}, {'completed' : contrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">계약전 알릴의무사항</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : contrPrgPhseCd === '05'}]">
                      <span class="list-step fs14rem">종료</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <!-- 계약자 end -->
        
          <!-- FC -->
          <mo-list-item>
            <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand" btn-area-class="firstdiv-full">
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <mo-badge class="mr8 black" text="" shape="status">FC</mo-badge>
                    <div class="fex-clumn">
                      <span class="name pr62">
                        <span>{{fcNm}}</span>
                        <mo-badge class="ml6 sm lightblue" shape="status" v-if="fcMobpsElstYn === 'N'" >시작</mo-badge>
                        <mo-badge class="ml6 sm lightgray" shape="status" v-else-if="fcMobpsElstYn === 'Y'" >종료</mo-badge>
                      </span>
                      <!-- 알림톡 순번이 있고, 14세이상인 경우 활성화 -->
                      <div class="mt10 fexTy2 mt10">
                        <mo-button sm class="ns-btn-round blue" color="primary" @click="fn_KaKaoSend('9')" :disabled="fcMobpsElstYn === 'Y'"><span class="mo-icon-msg-w"></span>알림톡발송</mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                        <mo-button sm class="ns-btn-round white ml10" color="normal" @click="fn_openWebViewURLAlert('9')" :disabled="fcMobpsElstYn === 'Y'">바로열기</mo-button>
                      </div>
                      <!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                      <!-- 문구추가 24.08.28:mijin -->
                      <div v-if="insrdCnsntObjYn === 'Y' && insrdCnsntScCd === 'U'" class="text_notice" >
                        <span>FC 서명전 피보험자 동의 완료해야 현장접수 가능</span> (미진행 시, 지점 접수만 가능)
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow fix-link-arrow">
                  <mo-icon icon="expand_menu" class="expand__icon" />
                  <span class="open">접기</span>
                  <span class="close">열기</span>
                </mo-button>
              </template>
              <template #content>
                <div class="list-item__contents">
                  <div class="ing-content">
                    <div class="ing-item" :class="[{'ing' : fcMobpsElstYn === 'N'}, {'completed' : fcMobpsElstYn === 'Y'}]">
                      <span class="list-step fs14rem">FC 서명전</span>
                    </div>
                    <div class="ing-item" :class="[{'ing' : fcMobpsElstYn === 'Y'}]">
                      <span class="list-step fs14rem">종료</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <!-- FC end -->
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_openOzViewer">청약서류</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS231P', 
  screenId: 'MSPPS231P', 

  components: {    
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pRowPopupData: Object
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      isFlashEnv : false,
      isAndroid : true, // 안드로이드 여부
      isTabletEvn  : false,       // 테블릿 환경 여부   
      orgOpenPrgPhseCd: ['insuredPrgPhseCd','addInsuredPrgPhseCd','dpstrPrgPhseCd','prtr1PrgPhseCd','prtr2PrgPhseCd','contrPrgPhseCd'],
      openPrgPhseCd: [],
      //confirmCamera: false,
      prgPnstaList: [], // 진행상황목록
      // standardAge15: 15, // 기준 나이 14 / 본인서명불가 나이
      standardAge15: 14, // 기준 나이 14 / 본인서명불가 나이
      prcd: '', // 상품코드 (ASR210400763_2021.04.23 PJO)
      stModelList: [], //S24 모바일청약 바로열기 및 서면동의촬영 서비스중지 모델명
      s24Model: '', // 기기 모델 정보

      // 계약자
      isContrStateShow: false, // 표시여부
      contrNm: '', // 이름
      contrMobslChnlCustId: '', // 모바일채널고객ID
      contrPrgPhseCd: '00', // 진행단계코드
      contrMobpsElstYn: 'N', // 전자서명완료 여부
      contrKakaoSn: '1', // 카카오톡알림발송순번
      contrUnder15: false, // 계약자 15세 미만
      contrSendType: { // 재발송유형
        key: 'K',
        label: '카카오톡'
      },

      // 피보험자
      isInsuredStateShow: false,
      insuredNm: '',
      insuredMobslChnlCustId: '', // 모바일채널고객ID
      insuredPrgPhseCd: '00',
      insuredMobpsElstYn: 'N',
      insuredKakaoSn: '0',
      insuredUnder15: false, // 피보험자 15세 미만
      insuredSendType: {
        key: 'K',
        label: '카카오톡'
      },
      insrdCnsntObjYn: 'N', // 피보험자동의대상여부
      insrdCnsntScCd: 'U', // 피보험자동의구분코드

      // 종피보험자
      isAddInsuredStateShow: false,
      addInsuredNm: '',
      addInsuredPrgPhseCd: '00',
      addInsuredMobpsElstYn: 'N',
      addInsuredKakaoSn: '0',
      addInsuredSendType: {
        key: 'K',
        label: '카카오톡'
      },

      // 예금주
      isDpstrStateShow: false,
      dpstrNm: '',
      dpstrMobslChnlCustId: '', // 모바일채널고객ID
      dpstrPrgPhseCd: '00',
      dpstrMobpsElstYn: 'N',
      dpstrKakaoSn: '0',
      dpstrSendType: {
        key: 'K',
        label: '카카오톡'
      },

      // 친권1
      isPrtr1StateShow: false,
      prtr1Nm: '',
      prtr1PrgPhseCd: '00',
      prtr1MobpsElstYn: 'N',
      prtr1KakaoSn: '0',
      prtr1SendType: {
        key: 'K',
        label: '카카오톡'
      },

      // 친권2
      isPrtr2StateShow: false,
      prtr2Nm: '',
      prtr2PrgPhseCd: '00',
      prtr2MobpsElstYn: 'N',
      prtr2KakaoSn: '0',
      prtr2SendType: {
        key: 'K',
        label: '카카오톡'
      },

      // FC
      fcNm: this.getStore('userInfo').getters.getUserInfo.userNm,
      fcMobpsElstYn: 'N',
      fcKakaoSn: '0',
      fcSendType: {
        key: 'K',
        label: '카카오톡'
      },

      sendTypes: [{ // 전송수단
        key: 'K',
        label: '카카오톡'
      },
      {
        key: 'T',
        label: '태블릿'
      }],
      sendUserInfo: {}, // 카카오알림톡 재발송유저 정보
      viewFormUrl: '', // 진행청약서류 보기 URL
      insrdCnsntType: {
        key: '',
        label: ''
      }, // 피보험자동의 수단
      insrdCnsntTypes: [// 피보험자동의 수단
        {
          key: 'L',
          label: '지문 촬영'
        },
        {
          key: 'D',
          label: '서면동의서 촬영'
        },
        {
          key: 'P',
          label: '나중에하기'
        }],
      addInsrdCnsntType: {
        key: '',
        label: ''
      }, // 종피보험자동의 수단
      addInsrdCnsntTypes: [ // 종보험자동의 수단
        {
          key: 'D',
          label: '서면동의서 촬영'
        },
        {
          key: 'P',
          label: '나중에하기'
        }],
        isCorpNomnPinsCoprResnt: false, //법인명의개인보험여부
        elstCoprPartnNo : ''
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  반드시  next(fals) OR next()  호출 
  beforeRouteEnter(to, from, next) { 
   
  },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  async created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    let lv_Vm = this
      
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-icon icon="msp-top-refresh" class="fs28" @click="fn_init"></mo-icon></div>',
        methods: {
          fn_init () {

            // 계약자
            lv_Vm.isContrStateShow = true
            lv_Vm.contrPrgPhseCd = '00' // 계약자진행단계코드

            // FC
            lv_Vm.fcMobpsElstYn = 'N' // FC 전자서명완료 여부

            // 모바일청약 중단건진행현황조회
            lv_Vm.fn_ServiceData('S1')
          },
        }
      }
    }

  console.log('>>>>isTabletEvn this.isTabletEvn='+this.isTabletEvn)
  // AOS 추가(2024.02.20)
  this.isAndroid = /Android|AOS/i.test(navigator.userAgent)
  console.log('★★★★ this.isAndroid=' + this.isAndroid)

  //전자서명 가능한 환경인지 체크 실행
  this.isTabletEvn = await PSCommUtil.fn_IsElstEnv()

  //지문촬영 가능 환경
  await this.fn_PocBioAuthEnv()
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    this.fn_init()
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () { },//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      return
    },
    /******************************************************************************
    * Function명  : fn_PocBioAuthEnv
    * 설명        : 지문촬영 가능 환경
    ******************************************************************************/
    async fn_PocBioAuthEnv() {
      
      //지문촬영 가능 환경 여부 조회 결과 : true / false 리턴
      this.isFlashEnv = await PSCommUtil.fn_IsBioAthntEnv() 
      console.log('★★★★★★★★지문촬영 가능 환경 여부 fn_PocBioAuthEnv() = '+this.isFlashEnv)
    },

     /******************************************************************************
     * Function명 : fn_PopupCustNmUiCard
     * 설명 : 계약자 피보험자 클릭시 nameUI 실행하는 함수
     ******************************************************************************/
    fn_PopupCustNmUiCard (pChnlCustId) {
      //to-do 고객네임카드 연계 화면 처리
      if(pChnlCustId !== '-'){
        this.lv_CustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
          properties: {
            chnlCustId: pChnlCustId,
            cnsltNo: PSServiceManager.getUserInfo('userId'), // ASR240200863 / 모바일청약 중단/완료건 화면에서 고객명 클릭시 고객정보 마스킹되는 현상 수정 
            //contNo : contNo
            parentId: 'MSPPS231P'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            }
          }
        })
      }
    }, 

    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/

    fn_init () {
      var t_This = this
      // 계약자
      this.isContrStateShow = true
      this.contrPrgPhseCd = '00' // 계약자진행단계코드

      // FC
      this.fcMobpsElstYn = 'N' // FC 전자서명완료 여부

      // 모바일청약 중단건진행현황조회
      this.fn_ServiceData('S1')

      //S24 모바일청약 바로열기 및 서면동의촬영 서비스중지 모델명
      this.fn_getS24MobpsStopModel()

      window.fdpbridge.exec('getDeviceModelPlugin', '', function (result) { 
        t_This.s24Model = result.data 
        console.log("Device model : " + t_This.s24Model)
      }, function (result) { })
    },

    fn_getS24MobpsStopModel () {
      var t_This = this
      let pParams = {}
      let btnId = 'S'
      pParams = {envId: 'S24_MOBPS_OPEN_STOP_MODEL'} 
      /*
      .그룹 명/ID  : S24 모바일청약 바로열기 및 서면동의촬영 서비스중지 모델명 / S24_MOBPS_OPEN_STOP_MODEL
      .설명: 상세 이름/  값
      1) 서비스 중지 모델 : SM-S921N, SM-S926N, SM-S928N
      */
      this.post(t_This, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            t_This.stModelList = response.body.returnDtlList
            console.log('fn_getS24MobpsStopModel.stModelList length:' + t_This.stModelList.length )
          }
        })
        .catch(function (error) {
          console.log('■■■ fn_getS24MobpsStopModel.......')
          window.vue.error(error)
        })
    },

 /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      
      switch (serviceName) {
        case 'S1': // 모바일청약 중단건진행현황조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS50S3'))
          pParams.data = {}
          pParams.data.predVuchId = this.pRowPopupData.vuchId // 영수증ID
          break
        case 'S2': // 카카오 알림톡 발송
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS50P2'))
          pParams.data = {}
          pParams.data.vuchId = this.pRowPopupData.vuchId // 영수증ID
          pParams.data.mobslElstScCd = 'A' // 모바일영업전자서명구분코드, A:모바일청약
          pParams.data.SpcCndtlElstSno = '0' // 특별조건부전자서명일련번호
          pParams.data.contPtcpScCd = this.sendUserInfo.contPtcpScCd // 계약관계인구분코드

          pParams.data.mobslChnlCustId = this.sendUserInfo.mobslChnlCustId // 모바일영업채널고객ID
          pParams.data.mobslChnlCustNm = this.sendUserInfo.mobslChnlCustNm // 모바일영업채널고객명
          break
        case 'S3': // 법인명의개인보험여부 확인 - ASR240900190 / [Sub]법인명의 개인보험 모청 - 공동대표 적용 (법인공동인증서) 
          console.log('S3!!')
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S1'))
          pParams.data = {}
          pParams.data.vuchId = this.pRowPopupData.vuchId // 영수증ID
          pParams.data.cnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트 번호
          break
        case 'S4' :// 법인명의개인보험 공동대표 확인 - ASR240900190 / [Sub]법인명의 개인보험 모청 - 공동대표 적용 (법인공동인증서) 
          console.log('S4!!')

          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS14S1'))
          pParams.data = {}
          let custAthntNm = {}
          custAthntNm.custId = this.elstCoprPartnNo
          custAthntNm.knbScCd = '21'
          pParams.data.custAthntNm = custAthntNm
          break
        default:
          break
      } // end switch

      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },

    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S1': // 모바일청약 중단건진행현황조회
          this.prgPnstaList = lv_data.pSElstIntrsCseListDVO
          this.viewFormUrl = lv_data.viewFormUrl

          if (this.prgPnstaList && this.prgPnstaList.length > 0) {
            this.prgPnstaList.forEach(pIteam => {
              switch (pIteam.contPtcpScCd) {
                case '2': // 피보험자
                  this.isInsuredStateShow = true
                  this.insuredNm = pIteam.mobslChnlCustNm
                  this.insuredMobslChnlCustId = pIteam.mobslChnlCustId // 모바일채널고객ID
                  this.insuredPrgPhseCd = pIteam.mobpsPrgPhseCd
                  if (this.insuredPrgPhseCd != '05' ) this.openPrgPhseCd.push('insuredPrgPhseCd')
                  this.insuredMobpsElstYn = pIteam.mobpsElstYn
                  this.insuredKakaoSn = pIteam.kakaoIapDspchSn
                  if (!this.$bizUtil.compareCurrentAge(pIteam.contPtcpRrnEncr.substr(0, 6), this.standardAge15)) {
                    // 피보험자가 15세미만
                    this.insuredUnder15 = true
                  }

                  this.insrdCnsntObjYn = pIteam.insrdCnsntObjYn // 피보험자동의대상여부
                  this.insrdCnsntScCd = pIteam.insrdCnsntScCd // 피보험자동의구분코드

                  break
                case '3': // 종피보험자
                  this.isAddInsuredStateShow = true
                  this.addInsuredNm = pIteam.mobslChnlCustNm
                  this.addInsuredPrgPhseCd = pIteam.mobpsPrgPhseCd
                  if (this.addInsuredPrgPhseCd != '05' ) this.openPrgPhseCd.push('addInsuredPrgPhseCd')
                  this.addInsuredMobpsElstYn = pIteam.mobpsElstYn
                  this.addInsuredKakaoSn = pIteam.kakaoIapDspchSn

                  // 종피보험자가 있는 경우, 지문촬영은 수행할수 없음
                  this.insrdCnsntTypes = this.addInsrdCnsntTypes
                  break
                case '4': // 친권1
                  this.isPrtr1StateShow = true
                  this.prtr1Nm = pIteam.mobslChnlCustNm
                  this.prtr1PrgPhseCd = pIteam.mobpsPrgPhseCd
                  if (this.prtr1PrgPhseCd != '05' ) this.openPrgPhseCd.push('prtr1PrgPhseCd')
                  this.prtr1MobpsElstYn = pIteam.mobpsElstYn
                  this.prtr1KakaoSn = pIteam.kakaoIapDspchSn
                  break
                case '5': // 친권2
                  this.isPrtr2StateShow = true
                  this.prtr2Nm = pIteam.mobslChnlCustNm
                  this.prtr2PrgPhseCd = pIteam.mobpsPrgPhseCd
                  if (this.prtr2PrgPhseCd != '05' ) this.openPrgPhseCd.push('prtr2PrgPhseCd')
                  this.prtr2MobpsElstYn = pIteam.mobpsElstYn
                  this.prtr2KakaoSn = pIteam.kakaoIapDspchSn
                  break
                case '6': // 예금주
                  this.isDpstrStateShow = true
                  this.dpstrNm = pIteam.mobslChnlCustNm
                  this.dpstrMobslChnlCustId = pIteam.mobslChnlCustId // 모바일채널고객ID
                  this.dpstrPrgPhseCd = pIteam.mobpsPrgPhseCd
                  if (this.dpstrPrgPhseCd != '05' ) this.openPrgPhseCd.push('dpstrPrgPhseCd')
                  this.dpstrMobpsElstYn = pIteam.mobpsElstYn
                  this.dpstrKakaoSn = pIteam.kakaoIapDspchSn
                  break
                case '1': // 계약자
                  this.isContrStateShow = true
                  this.contrNm = pIteam.mobslChnlCustNm // 이름
                  this.contrMobslChnlCustId = pIteam.mobslChnlCustId // 모바일채널고객ID
                  this.contrPrgPhseCd = pIteam.mobpsPrgPhseCd // 진행단계코드
                  if (this.contrPrgPhseCd != '05' ) this.openPrgPhseCd.push('contrPrgPhseCd')
                  this.contrMobpsElstYn = pIteam.mobpsElstYn // 전자서명 완료여부
                  this.contrKakaoSn = pIteam.kakaoIapDspchSn // 카카오톡알림발송순번
                  this.prcd = pIteam.prcd // 상품코드 (ASR210400763_2021.04.23 PJO)

                  // 꿈나무어린이 보험인경우  - 대상상품 : 꿈나무 어린이보험 限(상품코드 앞 여섯자리가 'LP0630'인 경우)
                  // 요청사항 : 계약관계자(계약자 또는 피보험자)가  만19세 미만인 경우  친권자1 이 대리 서명 진행
                  // if (!this.$bizUtil.isEmpty(pIteam.prcd) && pIteam.prcd.length >= 6) {
                  //   if (pIteam.prcd.substr(0, 6) === 'LP0630') {
                  //     this.standardAge15 = 19
                  //   }
                  // }
                  // (ASR220400794_22.05.10 PJO)_START
                  // 계약자/피보험자가 만14세 미만이면 or
                  // (상품유형관리코드RD(ZZPRDT_TYP_MG_ID) = W1(어린이보험 태아용) OR 상품코드 앞6자리 = LP0630) and  계약자/피보험자 연령 : 만19세 미만인 경우  친권인이 대신 작성
                  // 변경
                  // 계약자/피보험자가 만14세 미만이면 or
                  // (상품코드 앞6자리 = LP0665 OR 상품코드 앞6자리 = LP0630) and 계약자/피보험자 연령 : 만19세 미만인 경우  친권인이 대신 작성
                  // this.prdtTypMgCd = (!this.$bizUtil.isEmpty(pIteam.prdtTypMgCd)) ? pIteam.prdtTypMgCd : ' ' // 상품유형관리코드
                  this.prcd = (!this.$bizUtil.isEmpty(pIteam.prcd) && pIteam.prcd.length >= 6) ? pIteam.prcd.substr(0, 6) : ' ' // 상품코드
                  if (this.prcd === 'LP0665' || this.prcd === 'LP0630' || this.prcd === 'LP0715') { // 23.10 ASR230900955 꿈모아 어린이 건강보험 상품코드 LP0715추가
                    this.standardAge15 = 19 // 꿈나무 계/피 알림톡발송대상여부 19세로 세팅
                  }
                  console.log('상품코드:[' + pIteam.prcd + '],상품유형관리코드:[' + pIteam.prdtTypMgCd + '],standardAge15:' + this.standardAge15)
                  // (ASR220400794_22.05.10 PJO)_END

                  if ((pIteam.contPtcpRrnEncr.length !== 10 && !this.$bizUtil.compareCurrentAge(pIteam.contPtcpRrnEncr.substr(0, 6), this.standardAge15)) ||
                       pIteam.contPtcpRrnEncr.substr(0, 6) === '000000') { // 태아
                    // 계약자가 15세미만
                    this.contrUnder15 = true
                  }

                  break
                case '9': // FC
                  this.fcNm = pIteam.mobslChnlCustNm
                  this.fcPrgPhseCd = pIteam.mobpsElstYn
                  this.fcMobpsElstYn = pIteam.mobpsElstYn
                  this.fcKakaoSn = pIteam.kakaoIapDspchSn
                  break
                default:
                  break
              } // switch (pIteam.contPtcpScCd)
            }) // foreach 종료

            // 계피예 동일인경우, 피보험자, 예금주 행 숨김
            // if (this.contrMobslChnlCustId === this.insuredMobslChnlCustId &&
            //     this.insuredMobslChnlCustId === this.dpstrMobslChnlCustId) {
            //   this.isInsuredStateShow = false
            //   this.isDpstrStateShow = false
            // }
          }
          console.log('피보험자 발송순번' + this.insuredKakaoSn)
          console.log('종피보험자 발송순번' + this.addInsuredKakaoSn)
          console.log('친권1 발송순번' + this.prtr1KakaoSn)
          console.log('친권2 발송순번' + this.prtr2KakaoSn)
          console.log('예금주 발송순번' + this.dpstrKakaoSn)
          console.log('계약자 발송순번' + this.contrKakaoSn)
          console.log('FC 발송순번' + this.fcKakaoSn)

          // console.log('this.orgOpenPrgPhseCd ==== '+this.orgOpenPrgPhseCd)
          // console.log('this.openPrgPhseCd ==== '+this.openPrgPhseCd)
          // 계약자==>예금주 순에서 비보험자==>계약자 순으로 순서가 바뀌어서 시작 Expand를 고정으로 orgOpenPrgPhseCd 셋팅하고 첫번째openPrgPhseCd를 Expand 함
          let targetRef = ''
          for(let i = 0;i < this.orgOpenPrgPhseCd.length;i++){
            for(let j = 0;j < this.openPrgPhseCd.length;j++){
              if(this.orgOpenPrgPhseCd[i] == this.openPrgPhseCd[j]){
                targetRef = this.openPrgPhseCd[j]
              }
            }
            if(this.orgOpenPrgPhseCd[i] == targetRef) break;
          }
          console.log('targetRef ==== '+targetRef)
          if(targetRef) this.$refs['expItem_'+targetRef].isExpand = true

          // 법인명의 개인보험 공동대표 확인로직
          this.fn_ServiceData('S3')
                
          break
        case 'S2': // 카카오 알림톡 발송
          let sendRsltCd = lv_data.sendRsltCd

          if (sendRsltCd === 'E000') {
            this.getStore('confirm').dispatch('ADD', '알림톡이 발송되었습니다.')
          } else if (sendRsltCd === 'E001') {
            this.getStore('confirm').dispatch('ADD', '계약관계자의 연락처가 없습니다.')
          } else if (sendRsltCd === 'E002') {
            let contPtcpScCd = this.sendUserInfo.contPtcpScCd
            if (contPtcpScCd === '1') {
              this.getStore('confirm').dispatch('ADD', '계약자는 기타 계약관계자(피보험자, 예금주 等)의\n서명완료 후 진행이 가능합니다.\n\n*기타 계약관계자의 서명이 완료 되었는지\n먼저 확인 부탁드립니다.')
            } else if (contPtcpScCd === '4' || contPtcpScCd === '5') {
              this.getStore('confirm').dispatch('ADD', '친권인은 기타 계약관계자(계약자,피보험자,예금주 等)의\n서명완료 후 진행이 가능합니다.\n\n*기타 계약관계자의 서명이 완료 되었는지\n먼저 확인 부탁드립니다.')
            } else {
              this.getStore('confirm').dispatch('ADD', '기타 계약관계자(피보험자, 예금주 等)의\n서명완료 후 진행이 가능합니다.\n\n*기타 계약관계자의 서명이 완료 되었는지\n먼저 확인 부탁드립니다.')
            }
          }
          break
          case 'S3': // 법인명의개인보험여부 확인
            let corpNomnPinsYn = lv_data.corpNomnPinsYn
            console.log('corpNomnPinsYn : ',corpNomnPinsYn)
            this.elstCoprPartnNo = lv_data.elstPartnNo

            if(corpNomnPinsYn === 'Y') {
              this.fn_ServiceData('S4')
            } else {
              console.log('법인건 아님')
            }

            break
          case 'S4':// 법인명의개인보험 공동대표 확인
            console.log('S4')
            let tcrmGBobjNm = lv_data.tcrmGBobjNm.custRltnNm
            let resntBpID = ''
            let tmpCount = 0

            for(let i = 0; i < tcrmGBobjNm.length; i++){
              if(tcrmGBobjNm[i].custRltnTypCd === '21') {
                resntBpID = tcrmGBobjNm[i].custRltnToVal
                console.log('resntBpID : ',resntBpID)
                break
              }
            }
            for(let i = 0; i < tcrmGBobjNm.length; i++){
              if(tcrmGBobjNm[i].custRltnTypCd === '24' && tcrmGBobjNm[i].custRltnToVal !== resntBpID) {
                tmpCount++
                console.log('tmpCount : ',tmpCount)
              }
            }

            if(tmpCount >= 1){
              this.isCorpNomnPinsCoprResnt = true
            }
            break
        default:
          break
      } // switch (serviceName)
      
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /************************************************************************************************
     * Function명  : fn_KaKaoSend
     * 설명        : 카카오 알림톡 발송
     ************************************************************************************************/
    fn_KaKaoSend: function (contPtcpScCd) {
      this.prgPnstaList.forEach(pIteam => {
        if (contPtcpScCd === pIteam.contPtcpScCd) {
          this.sendUserInfo = pIteam
        }
      }) // this.prgPnstaList.forEach(pIteam => {

      // 카카오 알림톡 재발송
      this.fn_ServiceData('S2')
    },
   /******************************************************************************
   * Function명 : fn_openWebViewURLAlert
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_openWebViewURLAlert (contPtcpScCd) {
     let lv_Vm = this
     if (lv_Vm.stModelList.length > 0) {
      console.log("getDeviceModelPlugin model : " + lv_Vm.s24Model)
      let modelList = lv_Vm.stModelList.filter((item) => item.envCntnt === lv_Vm.s24Model)
      if(modelList.length > 0) {
        console.log(modelList[0].envCntnt)
        lv_Vm.fn_BottomAlert('갤럭시 S24 바로열기 기능은 5월에 오픈 예정입니다.<br> 알림톡으로 발송해 주시기 바랍니다.')
        return
      }
    }
    if (contPtcpScCd !== '9') {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: '고객님이 직접 작성하셔야 합니다. <br> 고객님과 대면하셨습니까?',
          single: true
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            setTimeout(function () {
              lv_Vm.fn_openWebViewURL(contPtcpScCd)
            }, 500)
          }
        }
      })
    }else{
      this.fn_openWebViewURL(contPtcpScCd)
    }
      
     
   },
    /************************************************************************************************
     * Function명  : fn_openWebViewURL
     * 설명        : 사랑On에서 WebView로 호출
     ************************************************************************************************/
    fn_openWebViewURL: function (contPtcpScCd) {
      let userKakaoSn = 0
      let isWebView = true

      // webView조회요청자의 알림톡 순번 확인
      this.prgPnstaList.forEach(pIteam => {
        if (contPtcpScCd === pIteam.contPtcpScCd) {
          this.sendUserInfo = pIteam
        }
      }) // this.prgPnstaList.forEach(pIteam => {
      userKakaoSn = this.sendUserInfo.kakaoIapDspchSn

      // webView조회요청자의 알림톡 순번보다 빠른 순번중에 모바일청약이 모두 완료되었는지 확인
      if (contPtcpScCd !== '9') {
        this.prgPnstaList.forEach(pIteam => {
          // webView 보다 낮은 순번의 전자서명이 완료되지 않은 건이 있는지 검증
          // 카카오 알림톡 대상 && 알림톡 순번 < 대상순번 && 전자서명미완료
          if (pIteam.kakaoIapDspchSn !== '0' && pIteam.kakaoIapDspchSn < userKakaoSn && pIteam.mobpsElstYn === 'N') {
            isWebView = false
          }
        }) // foreach 종료
      }

      // 계약자15세미만, 피보험자 15세미만인 경우
      // sendUserInfo를 친권1로 변경
      if ((contPtcpScCd === '1' && this.contrUnder15) || (contPtcpScCd === '2' && this.insuredUnder15)) {
        this.prgPnstaList.forEach(pIteam => {
          if (pIteam.contPtcpScCd === '4') { // 친권1
            this.sendUserInfo = pIteam
          }
        }) // foreach 종료
      }
      console.log('sendUserInfo:' + JSON.stringify(this.sendUserInfo))
      if (isWebView) {
        // webView 열기
        let isMobile = window.vue.getInstance('vue').$commonUtil.isMobile()
        let serverChoice = process.env.NODE_ENV
        let targetUrl = this.sendUserInfo.mobpsWhlUrlCntnt

        if (isMobile) {
          if(this.$commonUtil.getIsIOS()) { // 아이폰 환경
            // window.open(targetUrl, '_blank')
            window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {}); // 웹뷰 호출 방식으로 변경
          }else{
            if(serverChoice === 'local') {              
              window.open(targetUrl, '_blank') // 로컬테스트용
            } else {
              window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {})              
              // window.fdpbridge.exec('intentChromeBrowserPlugin', {uri: targetUrl}, () => {}, () => {}) // 외부 브라우저 호출로 테스트
            }
          }
          
        } else {
          // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
          if (serverChoice !== 'production') {
            window.open(targetUrl, '_blank')
          }
        }
        
      } else {
        if (contPtcpScCd === '1') {
          this.getStore('confirm').dispatch('ADD', '계약자는 기타 계약관계자(피보험자, 예금주 等)의\n서명완료 후 진행이 가능합니다.\n\n*기타 계약관계자의 서명이 완료 되었는지\n먼저 확인 부탁드립니다.')
        } else if (contPtcpScCd === '4' || contPtcpScCd === '5') {
          this.getStore('confirm').dispatch('ADD', '친권인은 기타 계약관계자(계약자,피보험자,예금주 等)의\n서명완료 후 진행이 가능합니다.\n\n*기타 계약관계자의 서명이 완료 되었는지\n먼저 확인 부탁드립니다.')
        } else {
          this.getStore('confirm').dispatch('ADD', '기타 계약관계자(피보험자, 예금주 等)의\n서명완료 후 진행이 가능합니다.\n\n*기타 계약관계자의 서명이 완료 되었는지\n먼저 확인 부탁드립니다.')
        }
      }
    }, // fn_openWebViewURL: function (contPtcpScCd)
    /************************************************************************************************
     * Function명  : fn_openOzViewer
     * 설명        : 사랑On에서 ozViewer호출
     ************************************************************************************************/
    fn_openOzViewer: function () {
      let isMobile = window.vue.getInstance('vue').$commonUtil.isMobile()
      let serverChoice = process.env.NODE_ENV

      if (isMobile) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: this.viewFormUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(this.viewFormUrl, '_blank')
        }
      }

    },
    /************************************************************************************************
     * Function명  : fn_InsrdCnsntOnClick
     * 설명        : 피보험자동의 변경
     ************************************************************************************************/
    fn_InsrdCnsntOnClick: function (contPtcpScCd) {
      let cnsntType = this.insrdCnsntType.key

      // 종피보험자인 경우
      if (contPtcpScCd === '3') {
        cnsntType = this.addInsrdCnsntType.key
      }
      let trnstId = 'txTSSPS50S4'
      let lv_Vm = this
      let param = {}
      param.predVuchId = lv_Vm.pRowPopupData.vuchId // 영수증ID
      param.mobslElstScCd = 'A' // 모바일영업전자서명구분코드, A:모바일청약
      param.SpcCndtlElstSno = '0' // 특별조건부전자서명일련번호
      param.contPtcpScCd = '9' // 계약관계인구분코드, 9:FC

      this.post(this, param, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            let fcMobpsElstYn = response.body.mobpsElstYn
            if (fcMobpsElstYn === 'N') {
              // 피보험자동의구분코드
              // U:미동의, D:서면동의서, L:생체인증
              if (cnsntType === 'D') {
                // 안드로이드 여부
                if(lv_Vm.isAndroid){
                  if (lv_Vm.stModelList.length > 0) {
                    console.log("getDeviceModelPlugin model : " + lv_Vm.s24Model)
                    let modelList = lv_Vm.stModelList.filter((item) => item.envCntnt === lv_Vm.s24Model)
                    if(modelList.length > 0) {
                      console.log(modelList[0].envCntnt)
                      lv_Vm.fn_BottomAlert('갤럭시 S24 피보험자 서면동의서 촬영 기능은 5월에 오픈 예정입니다.<br> 지문인증 혹은 지점접수로 진행해 주시기 바랍니다.')
                      return
                    }
                  }
                  lv_Vm.fn_Camera('9')
                }else{
                  lv_Vm.getStore('confirm').dispatch('ADD', '아이폰은 서면동의서 촬영이 불가합니다. <br><br> 지문촬영으로 진행해주세요.')
                }
              } else if (cnsntType === 'L') {
                // 모바일청약 진행현황조회 팝업 종료
                if(!lv_Vm.isFlashEnv){
                  // lv_Vm.fn_SendPush() // 테블릿 환경일 경우 푸쉬 전송
                  lv_Vm.getStore('confirm').dispatch('ADD', '지문촬영은 플래시가 있는 기기에서 진행가능합니다. <br><br> 스마트폰에서 진행해주세요.')
                }else{
                  lv_Vm.fn_Close()
                  lv_Vm.$router.push({name: 'MSPPS710M', params: {vuchId: lv_Vm.pRowPopupData.vuchId, mobslChnlCustId: lv_Vm.insuredMobslChnlCustId, mobslChnlCustNm: lv_Vm.insuredNm, hoshInfo: '-', bnfrInfo: '-'}})
                }
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', 'FC 전자서명이 완료되어, 피보험자동의를 수행할수 없습니다.')
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_Close: function () {
      this.$emit('onPopupClose', 'onPopupClose')
    },

    /************************************************************************************************
     * Function명  : fn_Camera
     * 설명        : 카메라 펑션
     ************************************************************************************************/
    fn_Camera: function (contPtcpScCd = '9', cnt = 0) {
      if (cnt === 0) {
        window.vue.getStore('progress').dispatch('UPDATE', true)// 프로그래스바 보임
      }
      if (cnt === 5) {
        window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
        //this.confirmCamera = true
        this.fn_onPositiveCamera()
        return
      }
      let lv_Vm = this
      window.fdpbridge.exec('CameraOnlyPlugin', {}, function (result) {
        if (result.data === 'cancel') {
          window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
          lv_Vm.getStore('confirm').dispatch('ADD', '피보험자 서면동의서 촬영을 취소하였습니다.')
        } else if (result.data === 'retake') {
          lv_Vm.fn_Camera(contPtcpScCd, cnt + 1)
        } else if (result.data === 'error') {
          window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
          lv_Vm.getStore('confirm').dispatch('ADD', '올바르게 촬영되지 않았습니다. 다시 실행해 주세요')
        } else {
          // 성공시 진위여부 (QR)
          let t_Img = result.data
          lv_Vm.fn_QRCheck(contPtcpScCd, t_Img)
        }
      }, function (result) {
        // 실패콜백
        window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
        lv_Vm.getStore('confirm').dispatch('ADD', '촬영에 실패하였습니다. 다시 실행해 주세요')
        //lv_Vm.confirmCamera = true
        lv_Vm.fn_onPositiveCamera()
      })
    },
    /************************************************************************************************
     * Function명  : fn_QRCheck
     * 설명        : qr로 진위인증 펑션
     ************************************************************************************************/
    fn_QRCheck: function (contPtcpScCd, img) {
      console.log('fn_QRCheck', img)
      let lv_Vm = this
      window.fdpbridge.exec('ScannerPlugin', {base64: img}, function (result) {
        // 이미지에 QR이 2개이상인 경우 Y로 회신
        if (result.data === 'Y') {
          lv_Vm.fn_SaveImg(contPtcpScCd, img)
        } else {
          window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
          //lv_Vm.confirmCamera = true
          lv_Vm.fn_onPositiveCamera()
        }
      }, function (result) {
        // 실패콜백
        window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
        lv_Vm.getStore('confirm').dispatch('ADD', 'QR인식에 실패하였습니다. 다시 실행해 주세요')
        //lv_Vm.confirmCamera = true
        lv_Vm.fn_onPositiveCamera()
      })
    },
    /************************************************************************************************
     * Function명  : fn_SaveImg
     * 설명        : 진위 확인 후 이미지 저장 프로세스
     ************************************************************************************************/
    fn_SaveImg: function (contPtcpScCd = '9', img) {
      let pParams = {}
      pParams.vuchId = this.pRowPopupData.vuchId // 영수증ID len 20
      pParams.contPtcpScCd = contPtcpScCd // 계약관계인구분코드 len 1
      // ttsd02004 ins
      pParams.mobslSrnId = 'MSPPS231P' // 모바일영업화면ID len 100
      pParams.srnCpntId = 'QRImage' // 화면컴포넌트ID len 50
      pParams.ozFrmId = 'picture' // OZ양식ID len 50
      pParams.ozFrmCpntId = 'QRImage' // OZ양식컴포넌트ID len 50
      console.log('fn_SaveImg', pParams)
      if (img.indexOf('data:image/png;base64,') === -1) { // e-form쪽에서 인식 안 될시 추가.
        img = 'data:image/png;base64,' + img
      }
      pParams.contPtcpElstImgCntnt = img // 계약관계인전자서명이미지내용 len INF
      // ttsd00002 upd
      pParams.insrdCnsntScCd = 'D' // 피보험자 동의 구분코드 서면동의서(D) len 1
      let trnstId = 'txTSSPS50I1'
      let lv_Vm = this
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.msgComm.msgCd === 'EFWS003') {
            window.vue.getStore('progress').dispatch('UPDATE', false) // 프로그래스바 숨김
            lv_Vm.getStore('confirm').dispatch('ADD', '피보험자 서면 동의서가 정상적으로 촬영되었습니다.')
            lv_Vm.fn_init() // 정상완료후 화면 새로고침
          } else {
            window.vue.getStore('progress').dispatch('UPDATE', false) // 프로그래스바 숨김
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생 했습니다. 다시 실행해 주세요 3')
          window.vue.getStore('progress').dispatch('UPDATE', false)// 프로그래스바 숨김
          //lv_Vm.confirmCamera = true
          lv_Vm.fn_onPositiveCamera()
        })
    },
    /************************************************************************************************
     * Function명  : fn_onPositiveCamera
     * 설명        : 촬영실패 팝업 -> 예 (재촬영)
     ************************************************************************************************/
    fn_onPositiveCamera () {
      let lv_Vm = this

      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content:' 피보험자 서면 동의서가 올바르게 촬영되지 않았습니다. 재촬영 하시겠습니까?',
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           setTimeout(function () {
            lv_Vm.fn_Camera()
           }, 500)
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })

    },
    /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업
    ******************************************************************************/
    fn_BottomAlert (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: contents,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_SendPush
     * 설명        : 푸시전송
     ************************************************************************************************/
    fn_SendPush () {
      let lv_Vm = this
      const trnstId = 'txTSSPS40U3'
      const auth = 'S'
      let pParams = {bioSvcTp: '1', bioSvcId: this.pRowPopupData.vuchId } // 영수증ID

      this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
        if (response.body) {
          if ( response.body.isPushYN === 'Y' ) { // 푸시정상적으로 전송
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ps')['EPSC042'] )
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '푸시전송실패 : 연결된 휴대폰이 없습니다.' )
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /************************************************************************************************
     * Function명  : fn_onNegative
     * 설명        : 촬영실패 팝업 -> 아니오
     ************************************************************************************************/
    // fn_onNegative () {
    //   this.confirmCamera = false
    // },

  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/


    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>